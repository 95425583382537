import {
    Box,
    Paper,
    Stack,
    Typography
} from '@mui/material';
import RidesharePanel from './RidesharePanel';
import BusSchedulePanel from './BusSchedulePanel';
import SocialMediaPanel from './SocialMediaPanel';
import WeatherPanel from './WeatherPanel';
import RideshareAltPanel from './RideshareAltPanel';

const Layout = () => {
    return (
        // <Grid container spacing={2}>
        //     <Grid item xs={6} md={8}>
        //         <Paper>Stuff here</Paper>
        //     </Grid>
        //     <Grid item xs={6} md={4}>
        //         <Paper></Paper>
        //     </Grid>
        // </Grid>
        <Stack sx={{
            height: '100vh',
            width: '100vw'
        }}
            direction="row">
            <Stack sx={{
                height: '100%',
                padding: '3rem',
                paddingTop: '2rem',
                paddingRight: '0',
                width: '65%'
            }}
                direction="column"
                spacing={1}
            >
                <Box sx={{
                    height: '85%'
                }}>
                    <BusSchedulePanel></BusSchedulePanel>
                </Box>
                <Box sx={{
                    height: '15%'
                }}>
                    <RideshareAltPanel></RideshareAltPanel>
                </Box>
            </Stack>
            <Box sx={{
                height: '100%',
                width: '4%'
            }}>
            </Box>
            <Stack sx={{
                backgroundColor: '#252722',
                borderLeft: '1px solid #b8b9b7',
                height: '100%',
                width: '35%',
            }}
                spacing={2}
                direction="column">
                <Box sx={{
                    height: '82%',
                    overflow: 'hidden',
                    width: '100%',
                }}>
                    <SocialMediaPanel></SocialMediaPanel>
                </Box>
                <Box sx={{
                    height: '18%',
                    width: '100%'
                }}>
                    <WeatherPanel></WeatherPanel>
                </Box>
            </Stack>
        </Stack >
    )
}

export default Layout;