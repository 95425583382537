
import {
    Box,
    Paper,
    Stack,
    SvgIcon,
    Typography
} from '@mui/material';
import moment from 'moment-timezone';
import { ReactComponent as ConnectorIcon1 } from "../images/svg/connector1.svg";
import { ReactComponent as ConnectorIcon2 } from "../images/svg/connector2.svg";
// import { ReactComponent as WarningIcon } from "../images/svg/warning.svg";
import { ReactComponent as MapMarkerIcon } from "../images/svg/map-marker.svg";
import ConnectorImage1 from "../images/connector1.png";
import ConnectorImage2 from "../images/connector2.png";
import MapMarkerImage from "../images/map-marker.png";
import WarningIconImage from "../images/warning-red.png";
import OnTimeIconImage from "../images/on-time-green.png";
import DelayedIconImage from "../images/delay-clock-red.png";

const BusPanel = ({
    schedule,
    routeId,
    direction
}) => {
    // Configure default timezone to PST
    const TIMEZONE = 'America/Los_Angeles';
    moment.tz.setDefault(TIMEZONE);

    const displayTimeFormat = 'hh:mm';
    const now = moment();
    const data = schedule.find(s => s.routeId === routeId && s.direction === direction);

    if (!data) {
        // No response or default data
        return (
            <Paper sx={{
                height: '100%',
                padding: '1rem',
                width: '100%',
            }}>
                <Typography
                    color="primary"
                    variant="h3"
                    component="h3">
                    Loading...
                </Typography>
            </Paper>
        );
    }

    const shortDirection = data.direction === 'East' ? 'E' : 'W';
    let routeName = `${data.routeId}${shortDirection}`;
    const routeNameFontSize = routeName.length > 2 ? '3.25rem' : '3.75rem';

    const getRouteName = () => {
        if (routeName.length > 2) {
            return (<>
                {routeName.substring(0, 3)}<br />{routeName.substring(3, 4)}
            </>);
        }
        return routeName;
    }

    const gtfsTimeToUnix = (time, dayOffset) => {
        let strTime = time;
        let parts = time.split(':');
        let unix;

        let additionalDayOffset = Math.floor(Math.max(0, parseInt(parts[0])) / 24);
        let hour = `${parseInt(parts[0]) % 24}`.padStart(2, '0');

        strTime = [hour, parts[1], parts[2]].join(':');
        unix = moment(strTime, 'HH:mm:ss').add(dayOffset + additionalDayOffset, 'd').unix();

        return unix;
    }

    const parseGtfsTime = (time, dayOffset) => {
        let strTime = time;
        let additionalDayOffset = 0;

        if (time) {
            let parts = time.split(':');
            let unix;

            additionalDayOffset = Math.floor(Math.max(0, parseInt(parts[0])) / 24);
            let hour = `${parseInt(parts[0]) % 24}`.padStart(2, '0');

            strTime = [hour, parts[1], parts[2]].join(':');
        }
        time = moment(strTime, 'HH:mm:ss').add(dayOffset + additionalDayOffset, 'd');

        return time;
    }

    if (!data.startDepartureTime) {
        // Check tomorrow
        return (
            <Paper sx={{
                height: '100%',
                padding: '1rem',
                width: '100%',
            }}>
                <Stack direction="row" spacing={3}>
                    <Box
                        sx={{
                            minWidth: '8rem',
                            width: '15%'
                        }}>
                        <Typography
                            sx={{
                                fontSize: routeNameFontSize,
                                fontWeight: 700,
                                lineHeight: 0.9,
                                marginTop: '1.5rem',
                                textAlign: 'center',
                                wordWrap: 'break-word'
                            }}
                            variant="h2"
                            component="h2">{ }
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            height: '100%',
                            width: '100%',
                        }}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography
                            color="secondary"
                            variant="h3"
                            component="h3">
                            Coming up..
                        </Typography>
                    </Box>
                </Stack>
            </Paper>
        );
    }

    let departureTimeActual;
    const departureTime = moment.unix(data.startDepartureTimeUnix);//moment(data.startDepartureTime, 'HH:mm:ss');
    // const departureTime = moment(data.startDepartureTime, 'HH:mm:ss').add(data.dayOffset, 'd');

    const nextDepartureTime = moment.unix(data.nextStartDepartureTimeUnix); //moment(data.nextDepartureTime, 'HH:mm:ss');
    // const nextDepartureTime = moment(data.nextDepartureTime, 'HH:mm:ss').add(data.dayOffset, 'd');
    const nextDepartsMinutes = `${moment.duration(nextDepartureTime.diff(now)).humanize()}`.toUpperCase();

    let currentDeparture = parseGtfsTime(data.startDepartureTime).format(displayTimeFormat);
    let currentArrivalAtDest = parseGtfsTime(data.endArrivalTime).format(displayTimeFormat);
    let nextDeparture = parseGtfsTime(data.nextStartDepartureTime).format(displayTimeFormat);
    let nextArrivalAtDest = parseGtfsTime(data.nextEndArrivalTime).format(displayTimeFormat);
    const destination = data.endStopDisplay?.toUpperCase();

    // Updates
    let currentDepartureActual;
    let currentArrivalActual;
    let nextDepartureActual;
    let nextArrivalActual;
    let delay = 0;
    let isDelayed = false;
    let note = 'On Time';
    let alertMessage = null;
    let showWarningIcon = false;

    if (data.startTripUpdate) {
        departureTimeActual = moment.unix(data.startTripUpdate.departure);
        currentDepartureActual = departureTimeActual.format(displayTimeFormat);
        delay = data.startTripUpdate.departure - data.startDepartureTimeUnix;
        console.log(`Current scheduled departure: ${currentDeparture}, actual departure: ${currentDepartureActual}`);
    }
    if (data.endTripUpdate && data.endTripUpdate.arrival) {
        currentArrivalActual = moment.unix(data.endTripUpdate.arrival).format(displayTimeFormat);
        console.log(`Current scheduled arrival: ${currentArrivalAtDest}, actual arrival: ${currentArrivalActual}`);
    }
    if (data.nextStartTripUpdate && data.nextStartTripUpdate.departure) {
        nextDepartureActual = moment.unix(data.nextStartTripUpdate.departure).format(displayTimeFormat);
        console.log(`Next scheduled departure: ${nextDeparture}, actual departure: ${nextDepartureActual}`);
    }
    if (data.nextEndTripUpdate && data.nextEndTripUpdate.arrival) {
        nextArrivalActual = moment.unix(data.nextEndTripUpdate.arrival).format(displayTimeFormat);
        console.log(`Next scheduled arrival: ${nextArrivalAtDest}, actual arrival: ${nextArrivalActual}`);
    }

    if (data.alertMessage) {
        // There is a disruption
        alertMessage = data.alertMessage ?? '';
        showWarningIcon = true;
    } else {
        if (Math.abs(delay) > 59) { // More than a minute
            isDelayed = true;
            const delayDuration = moment.duration(delay, 'seconds');
            const delayHumanized = delayDuration.humanize();
            const absDelayMinutes = Math.abs(Math.floor(delayDuration.asMinutes()));
            const absDelayHours = Math.abs(Math.floor(delayDuration.asHours()));
            let count;
            let unit;
            console.log('delay', delay);
            console.log('delayMinutes', absDelayMinutes);
            if (absDelayMinutes > 59) {
                count = Math.max(1, absDelayHours);
                unit = absDelayHours === 1 ? 'hr' : 'hrs';
            } else {
                count = Math.max(1, absDelayMinutes);
                unit = absDelayMinutes === 1 ? 'min' : 'mins';
            }
            if (delay > 0) {
                note = `Delayed ${count} ${unit}`;
            } else {
                note = `Early ${count} ${unit}`;
            }
        }
    }

    // Figure out countdown
    const departsMinutesDiff = (departureTimeActual ?? departureTime).diff(now, 'minutes');
    const departsHoursDiff = (departureTimeActual ?? departureTime).diff(now, 'hours');
    const departsMinutes = `${Math.max(0, departsMinutesDiff)}`.padStart(2, '0');
    const departsHours = `${Math.min(9, departsHoursDiff)}+`;
    const departsToday = moment.unix(data.startDepartureTimeUnix).format('DD') === now.format('DD');

    const departsCountdown = departsMinutesDiff > 59 ? departsHours : departsMinutes;
    const departsUnit = departsMinutesDiff > 59 ? 'HOURS' : 'MINUTES';

    return (
        <Paper sx={{
            height: '100%',
            padding: '1rem',
            paddinBottom: '1.75rem',
            paddingTop: '1.75rem',
            width: '100%',
        }}>
            <Stack
                direction="row"
                alignItems="center"
                spacing={1.5}>
                <Box
                    sx={{
                        minWidth: '6rem',
                        width: '15%'
                    }}>
                    <Typography
                        sx={{
                            fontSize: routeNameFontSize,
                            fontWeight: 'bold',
                            lineHeight: 0.9,
                            textAlign: 'center',
                            wordWrap: 'break-word'
                        }}
                        variant="condensed"
                        component="h2">
                        {getRouteName()}
                    </Typography>
                </Box>
                <Stack
                    sx={{
                        width: '100%'
                    }}
                    direction="column"
                    spacing={0.25}>
                    <Stack direction="row">
                        <Stack direction="column"
                            sx={{
                                width: '150px'
                            }}>
                            <Typography
                                sx={{
                                    fontWeight: 'bold'
                                }}
                                color="secondary"
                                variant=""
                                component="">
                                DEPARTS
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '4.3rem',
                                    fontWeight: 'bold',
                                    lineHeight: 0.8,
                                }}
                                color="secondary"
                                variant="h2"
                                component="h2">
                                {departsCountdown}
                            </Typography>
                            <Typography
                                sx={{
                                    fontWeight: 'bold'
                                }}
                                color="secondary"
                                variant=""
                                component="">
                                {departsUnit}
                            </Typography>
                        </Stack>
                        <Stack direction="column"
                            sx={{
                                width: '100%'
                            }}>
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center">
                                <Typography
                                    sx={{
                                        fontSize: '2.25rem',
                                        fontWeight: 'bold'
                                    }}
                                    color="secondary"
                                    variant=""
                                    component="">
                                    {currentDepartureActual ?? currentDeparture}
                                </Typography>
                                {/* <SvgIcon
                                    sx={{
                                        fontSize: '3rem',
                                    }}>
                                    <ConnectorIcon2></ConnectorIcon2>
                                </SvgIcon> */}
                                <img src={ConnectorImage2} style={{
                                    height: 'auto',
                                    width: '48px',
                                }} />
                                <Typography
                                    sx={{
                                        fontSize: '1.5rem',
                                        fontWeight: 'bold'
                                    }}
                                    color="secondary"
                                    variant=""
                                    component="">
                                    {currentArrivalActual ?? currentArrivalAtDest}
                                </Typography>
                            </Stack>
                            <Stack
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                }}
                                direction="row"
                                alignItems="center"
                                spacing={0}>
                                <Stack
                                    sx={{
                                        width: '140px'
                                    }}
                                    spacing={1}
                                    alignItems="left"
                                    direction="column">
                                    {showWarningIcon &&
                                        <Stack
                                            alignItems="center"
                                            direction="row">
                                            <img src={WarningIconImage} style={{
                                                height: 'auto',
                                                marginRight: '10px',
                                                width: '22px',
                                            }} />
                                            <Typography
                                                sx={{
                                                    color: '#ec2227',
                                                    fontSize: '0.75rem',
                                                    fontWeight: 'bold',
                                                    lineHeight: 0.95
                                                }}
                                                variant=""
                                                component="">
                                                {alertMessage.toUpperCase()}
                                            </Typography>
                                        </Stack>
                                    }
                                    {
                                        !showWarningIcon &&
                                        <Box sx={{
                                            height: '1rem'
                                        }}></Box>
                                    }
                                    <Stack
                                        alignItems="center"
                                        direction="row">
                                        <img src={isDelayed ? DelayedIconImage : OnTimeIconImage} style={{
                                            height: 'auto',
                                            marginRight: '10px',
                                            width: '22px',
                                        }} />
                                        <Typography
                                            sx={{
                                                fontSize: '0.75rem',
                                                fontWeight: 'bold',
                                                lineHeight: 0.95
                                            }}
                                            color={isDelayed ? '#ec2227' : 'green'}
                                            variant=""
                                            component="">
                                            {note.toUpperCase()}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack
                                    sx={{
                                        width: '110px'
                                    }}
                                    alignItems="center"
                                    direction="column">
                                    <img src={MapMarkerImage} style={{
                                        height: 'auto',
                                        width: '22px',
                                    }} />
                                    <Typography
                                        sx={{
                                            fontSize: '0.75rem',
                                            fontWeight: 'bold',
                                            lineHeight: 0.95,
                                            marginTop: '0.5rem',
                                            maxWidth: '110px',
                                            textAlign: 'center'
                                        }}
                                        color="secondary"
                                        variant="p"
                                        component="p">
                                        {destination}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack direction="row" spacing={0} alignItems="center">
                        <Stack
                            sx={{
                                width: '110px'
                            }}
                            direction="column">
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    lineHeight: 1.1
                                }}
                                color="white"
                                variant=""
                                component="">
                                NEXT BUS
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '0.75rem',
                                    fontWeight: 'bold'
                                }}
                                color="white"
                                variant=""
                                component="">
                                {nextDepartsMinutes}
                            </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                        >
                            <Typography
                                sx={{
                                    fontSize: '2.25rem',
                                    fontWeight: 'bold'
                                }}
                                color="primary"
                                variant=""
                                component="">
                                {nextDepartureActual ?? nextDeparture}
                            </Typography>
                            {/* <SvgIcon
                                sx={{
                                    fontSize: '3rem',
                                }}>
                                <ConnectorIcon1></ConnectorIcon1>
                            </SvgIcon> */}
                            <img src={ConnectorImage1} style={{
                                height: 'auto',
                                width: '48px',
                            }} />
                            <Typography
                                sx={{
                                    fontSize: '1.5rem',
                                    fontWeight: 'bold'
                                }}
                                color="primary"
                                variant=""
                                component="">
                                {nextArrivalActual ?? nextArrivalAtDest}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Paper>
    );
}

export default BusPanel;