import {
    Box,
    Paper,
    Stack,
    SvgIcon,
    Typography
} from '@mui/material';
import Image from 'mui-image';
import { useEffect, useState } from 'react';
import axios from 'axios';
import ParklineLogo from "../images/parkline-logo.png";
import { ReactComponent as SunIcon } from "../images/svg/sun.svg";

// import SunImage from "../images/sun.png";
import SunnyImage from '../images/weather/sunny.png';
import MostlySunnyImage from '../images/weather/mostly-sunny.png';
import CloudyImage from '../images/weather/cloudy.png';
import MostlyCloudyImage from '../images/weather/mostly-cloudy.png';
import WindyImage from '../images/weather/windy.png';
import RainyImage from '../images/weather/rainy.png';

const WeatherPanel = () => {

    let interval = null;
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const [weather, setWeather] = useState({
        current: {},
        forecast: {
            conditions: {
                today: {},
                tomorrow: {},
            },
            today: {},
            tomorrow: {},
        }
    });

    const loadWeather = async () => {
        console.log('Loading weather..');
        try {
            const response = await axios.get(`${API_BASE_URL}/api/weather`);
            setWeather(response.data);
            console.log(response.data);
        } catch (error) {
            console.log(error);

        }
    };

    useEffect(() => {
        loadWeather();
    }, []);

    useEffect(() => {
        interval = setInterval(() => {
            loadWeather();
        }, 5 * 60 * 1000); // 5 min
        return () => clearInterval(interval);
    }, []);

    const getWeatherConditionFromIcon = (icon) => {
        if (icon) {
            if (icon.includes("bkn")) {
                return "mostly cloudy";
            } else if (icon.includes("ovc")) {
                return "cloudy";
            } else if (icon.includes("few")) {
                return "mostly sunny";
            } else if (icon.includes("sct")) {
                return "partly cloudy";
            } else if (icon.includes("sunny")) {
                return "sunny";
            } else if (icon.includes("wind")) {
                return "windy";
            } else if (icon.includes("rain") || icon.includes("shower")) {
                return "rainy";
            }
        }
        return 'sunny';
    }

    const getImageFromWeatherCondition = (condition) => {
        console.log('condition:', condition);
        switch (condition) {
            case 'cloudy':
                return CloudyImage;
            case 'mostly cloudy':
                return MostlyCloudyImage;
            case 'sunny':
                return SunnyImage;
            case 'mostly sunny':
                return MostlySunnyImage;
            case 'windy':
                return WindyImage;
            case 'rainy':
                return RainyImage;
            default:
                return SunnyImage;
        }
    }

    const todayConditionImage = getImageFromWeatherCondition(
        getWeatherConditionFromIcon(weather.forecast?.conditions?.today?.icon)
    );

    const tomorrowConditionImage = SunnyImage;

    return (
        <Box sx={{
            // backgroundColor: '#222',
            borderTop: '1px solid #b8b9b7',
            height: '100%',
            padding: '3rem',
            width: '100%'
        }}>
            <Stack
                sx={{
                    height: '100%',
                    paddingBottom: '0.5rem',
                    width: '100%'
                }}
                spacing={2}
                // alignItems="flex-end"
                alignItems="center"
                direction="row">
                <Stack
                    alignItems="center"
                    direction="column">
                    {/* <SvgIcon sx={{
                        fontSize: '50px',
                    }}>
                        <SunIcon ></SunIcon>
                    </SvgIcon> */}
                    <img src={todayConditionImage} style={{
                        height: 'auto',
                        width: '50px',
                    }} />
                    <Typography
                        sx={{
                            fontSize: '2.5rem',
                            textAlign: 'center'
                        }}
                        variant="h3"
                        component="h3">{weather?.current?.temperature ?? '00'}°</Typography>
                </Stack>
                <Box sx={{
                    borderRight: '1px solid #fff',
                    height: '90%',
                    marginRight: '5px'
                }}></Box>
                <Stack
                    direction="column"
                    spacing={1}>
                    <Stack
                        alignItems="center"
                        direction="row">
                        {/* <SvgIcon sx={{
                            fontSize: '32px',
                            marginRight: '10px',
                        }}>
                            <SunIcon ></SunIcon>
                        </SvgIcon> */}
                        <img src={tomorrowConditionImage} style={{
                            height: 'auto',
                            marginRight: '10px',
                            width: '32px',
                        }} />
                        <Stack direction="column">
                            <Typography
                                color="parklineGrey.main"
                                variant="h6"
                                component="h6">Today</Typography>
                            <Typography
                                variant="h6"
                                component="h6">H:{weather?.forecast.today.high ?? '00'}° L:{weather?.forecast.today.low ?? '00'}°</Typography>
                        </Stack>
                    </Stack>
                    <Stack
                        alignItems="center"
                        direction="row">
                        {/* <SvgIcon sx={{
                            fontSize: '32px',
                            marginRight: '10px',
                        }}>
                            <SunIcon ></SunIcon>
                        </SvgIcon> */}
                        <img src={todayConditionImage} style={{
                            height: 'auto',
                            marginRight: '10px',
                            width: '32px',
                        }} />
                        <Stack direction="column">
                            <Typography
                                color="parklineGrey.main"
                                variant="h6"
                                component="h6">Tomorrow</Typography>
                            <Typography
                                variant="h6"
                                component="h6">H:{weather?.forecast.tomorrow.high ?? '00'}° L:{weather?.forecast.tomorrow.low ?? '00'}°</Typography>
                        </Stack>
                    </Stack>
                </Stack>

                <img src={ParklineLogo} style={{
                    height: '110px',
                    marginLeft: 'auto',
                    marginRight: '0.5rem',
                    width: 'auto',
                }} />
                {/* <Image
                    wrapperStyle={{
                        marginLeft: 'auto',
                        width: 'auto'
                    }}
                    duration={0}
                    src="logo512.png"
                    fit="contain" /> */}
            </Stack>
        </Box>
    );
}

export default WeatherPanel;