
import {
    Box,
    Paper,
    Stack,
    Typography
} from '@mui/material';
import OneBusQrImage from "../images/onebus-qr.png";

const RideshareAltPanel = () => {
    return (
        <Box
            sx={{
            height: '100%',
            width: '100%'
        }}>
            <Stack
                sx={{
                    height: '100%',
                    marginLeft: '1rem',
                    width: '100%'
                }}
                spacing={1}
                direction="column">
                <Stack
                    direction="row">
                    <Box sx={{
                        height: '2.15rem',
                        marginTop: '0.5rem',
                        width: '50%'
                    }}>
                        {/* <Typography
                            variant="h3"
                            component="h3">xx</Typography> */}
                    </Box>
                </Stack>
                <Box sx={{
                    height: '100%',
                    width: '100%'
                }}>
                    <Stack
                        direction="row"
                        spacing={0}
                        alignItems="center"
                        justifyContent="center"
                    >
                        
                        <Stack
                            alignItems="center"
                            direction="column">
                            <img src={OneBusQrImage} style={{
                                height: 'auto',
                                width: '72px',
                            }} />
                        </Stack>
                        <Typography
                            sx={{
                                color: '#b9bab8',
                                fontSize: '2rem',
                                fontWeight: '500',
                                lineHeight: '2.4rem',
                                marginLeft: '2rem',
                                // width: '280px'
                            }}
                            variant="h5"
                            component="h5">
                            Scan here to download the One Bus Away App to get<br/> the most up-to-the-minute information for your bus.
                        </Typography>
                    </Stack>
                </Box>
            </Stack >
        </Box >
    );
}

export default RideshareAltPanel;