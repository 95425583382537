import { useEffect, useState } from 'react';
import {
    Box,
    Paper,
    Stack,
    SvgIcon,
    Typography
} from '@mui/material';
import moment from 'moment-timezone';
import axios from 'axios';
import Image from 'mui-image';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import BusPanel from './BusPanel';
import { ReactComponent as BusIcon } from "../images/svg/bus.svg";
import MtsLogo from "../images/mts.png";
import BusImage from "../images/bus.png";

const BusSchedulePanel = () => {

    // Configure default timezone to PST
    const TIMEZONE = 'America/Los_Angeles';
    moment.tz.setDefault(TIMEZONE);

    const [loading, setLoading] = useState(false);
    const [currentTime, setCurrentTime] = useState('');
    const [currentDate, setCurrentDate] = useState('date');
    const [busSchedule, setBusSchedule] = useState(
        [
            {
                routeId: '1',
                direction: 'East',
                startStopId: '10533',
                endStopId: '13410',
                endStopDisplay: 'La Mesa',
            },
            {
                routeId: '1',
                direction: 'West',
                startStopId: '13430',
                endStopId: '94048',
                endStopDisplay: 'Fashion Valley',
            },
            {
                routeId: '6',
                direction: 'East',
                startStopId: '10533',
                endStopId: '11693',
                endStopDisplay: 'University Avenue',
            },
            {
                routeId: '6',
                direction: 'West',
                startStopId: '13430',
                endStopId: '94050',
                endStopDisplay: 'Fashion Valley',
            },
            {
                routeId: '215',
                direction: 'East',
                startStopId: '13554',
                endStopId: '99017',
                endStopDisplay: 'SDSU Transit Center',
            },
            {
                routeId: '215',
                direction: 'West',
                startStopId: '99199',
                endStopId: '99791',
                endStopDisplay: 'Downtown (Santa Fe Depot)',
            }
        ]
    );

    let interval = null;
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const getTime = () => {
        return moment().format('h:mm A');
    };

    const getDate = () => {
        return moment().format('dddd, MMMM D');
    }

    const updateDateTime = () => {
        setCurrentTime(getTime());
        setCurrentDate(getDate());
    }

    useEffect(() => {
        setCurrentTime(getTime());
        interval = setInterval(() => {
            updateDateTime();
            loadSchedule();
        }, 30 * 1000);

        updateDateTime();
        return () => clearInterval(interval);
    }, []);

    const loadSchedule = async () => {
        try {
            var config = {
                method: 'get',
                url: `${API_BASE_URL}/api/gtfs/schedule`,
                headers: {
                }
            };
            const response = await axios(config);
            // const mapped = response.data.map(p => {
            //     const node = p.node;
            //     return {
            //         id: node.id,
            //         imageUrl: node.display_url,
            //         squareImageUrl: node.thumbnail_resources.find(t => t.config_width === 640)?.src,
            //         text: node.edge_media_to_caption?.edges[0]?.node?.text
            //     }
            // });
            setBusSchedule(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        loadSchedule();
    }, []);

    const getStopDetails = (routeId, direction) => {
        const stopData = busSchedule.find(s => s.routeId === routeId && s.direction === direction);
        if (stopData) {
            return (
                <BusPanel stopData={stopData}></BusPanel>
            )
        }
    };

    return (
        <Box sx={{
            height: '100%',
            width: '100%'
        }}>
            <Stack
                sx={{
                    height: '100%',
                    width: '100%'
                }}
                spacing={1}
                direction="column">
                <Stack
                    direction="row">
                    <Box sx={{
                        width: '50%'
                    }}>
                        <Stack direction="row" alignItems="center">
                            {/* <DirectionsBusIcon color="secondary" sx={{
                                fontSize: '2.25rem',
                                marginRight: '0.5rem'
                            }}></DirectionsBusIcon> */}
                            <img src={MtsLogo} style={{
                                height: 'auto',
                                marginRight: '1rem',
                                width: '140px',
                            }} />
                            {/* <SvgIcon
                                sx={{
                                fontSize: '1.5rem',
                                marginRight: '0.7rem'
                            }}>
                                <BusIcon></BusIcon>
                            </SvgIcon> */}
                            <img src={BusImage} style={{
                                height: 'auto',
                                marginRight: '20px',
                                width: '26px',
                            }} />
                            <Typography
                                sx={{
                                    fontSize: '2.75rem'
                                }}
                                variant="h3"
                                component="h3">Bus Schedule</Typography>
                        </Stack>
                    </Box>
                    <Box sx={{
                        marginTop: '1.5rem',
                        width: '50%',
                        textAlign: 'right'
                    }}>
                        <Typography
                            color="parklineGrey.main"
                            variant="h3"
                            component="h3">
                            <span style={{
                                fontSize: '1.2rem',
                                marginRight: '1.5rem'
                            }}>
                                {currentDate}
                            </span>
                            <span style={{
                                color: 'white'
                            }}
                            >{currentTime}</span></Typography>
                    </Box>
                </Stack>
                <Stack
                    sx={{
                        width: '100%',
                        height: '100%',
                        marginTop: '0.25rem'
                    }}
                    spacing={1.5}
                    direction="row">
                    <Stack sx={{
                        height: '100%',
                        width: '50%'
                    }}
                        spacing={1.5}
                        direction="column">
                        <BusPanel schedule={busSchedule} routeId="1" direction="West"></BusPanel>
                        <BusPanel schedule={busSchedule} routeId="6" direction="West"></BusPanel>
                        <BusPanel schedule={busSchedule} routeId="215" direction="West"></BusPanel>
                    </Stack>
                    <Stack sx={{
                        height: '100%',
                        width: '50%'
                    }}
                        spacing={1.5}
                        direction="column">
                        <BusPanel schedule={busSchedule} routeId="1" direction="East"></BusPanel>
                        <BusPanel schedule={busSchedule} routeId="6" direction="East"></BusPanel>
                        <BusPanel schedule={busSchedule} routeId="215" direction="East"></BusPanel>
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    );
}

export default BusSchedulePanel;