import { useEffect, useState } from 'react';
import axios from 'axios';

const AutoRefresh = () => {

    let interval = null;
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const [shouldRefresh, setShouldRefresh] = useState(false);

    const checkRefresh = async () => {
        console.log('Checking if we should refresh..');
        try {
            const response = await axios.get(`${API_BASE_URL}/api/refresh`);
            setShouldRefresh(response.data.shouldRefresh);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        interval = setInterval(() => {
            checkRefresh();
        },  30 * 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (shouldRefresh) {
            setShouldRefresh(false);
            window.location.reload(true);
        }
    }, [shouldRefresh])

    return null;
}

export default AutoRefresh;