import { createTheme } from '@mui/material/styles';
import { green, purple } from '@mui/material/colors';

import InterBold from './fonts/Inter-Bold.ttf';
import InterMedium from './fonts/Inter-Medium.ttf';

import RobotoBold from './fonts/Roboto-Bold.ttf';
import RobotoCondensedBold from './fonts/RobotoCondensed-Bold.ttf';
import RobotoMonoMedium from './fonts/RobotoMono-Medium.ttf';

const theme = createTheme({
    palette: {
        primary: {
            main: '#fff'
        },
        secondary: {
            main: '#d6e11b',
        },
        background: {
            default: '#1c1e19',
            paper: '#2c2e29'
        },
        text: {
            primary: '#fff',
            secondary: '#d6e11b'
        },
        parklineGrey: {
            main: '#adaea4'
        }
    },
    typography: {
        fontSize: 14,
        fontFamily: 'Roboto',
        h3: {
            fontSize: '2.15rem',
            fontWeight: 'bold',
        },
        h4: {
            fontWeight: 'bold'
        },
        h6: {
            fontSize: '1.1rem',
            fontWeight: 'bold',
            lineHeight: '1.33rem'
        },
        condensed: {
            fontFamily: 'Roboto Condensed'
        },
        mono: {
            fontFamily: 'Roboto Mono'
        }
    },
    components: {
        MuiCssBaseline: {
            // styleOverrides: `
            //     @font-face {
            //         font-family: 'Inter';
            //         font-style: normal;
            //         font-display: swap;
            //         font-weight: 400;
            //         src: local('Inter-Bold'), local('Inter-Medium'), url(${InterBold}), url(${InterMedium}) format('ttf');
            //         unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            //     }
            // `,
            // styleOverrides: `
            //     @font-face {
            //         font-family: 'Roboto';
            //         font-style: normal;
            //         font-display: swap;
            //         font-weight: 700;
            //         src: local('Roboto-Bold'), url(${RobotoBold}), format('ttf');
            //         unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            //     }
            //     @font-face {
            //         font-family: 'Roboto Condensed';
            //         font-style: normal;
            //         font-display: swap;
            //         font-weight: 'bold';
            //         src: local('RobotoCondensed-Bold'), url(${RobotoCondensedBold}), format('ttf');
            //         unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            //     }
            //     @font-face {
            //         font-family: 'Roboto Mono';
            //         font-style: normal;
            //         font-display: swap;
            //         font-weight: 500;
            //         src: local('RobotoMono-Medium'), url(${RobotoMonoMedium}), format('ttf');
            //         unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            //     }
            // `,            
        },
    }
});

export default theme;