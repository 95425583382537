
import {
    Box,
    Paper,
    Stack,
    Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Image from 'mui-image';
import InstaLogo from "../images/insta.png";

const SocialMediaPanel = () => {

    const [posts, setPosts] = useState([]);
    const [postIndex, setPostIndex] = useState(-1);
    const [post, setPost] = useState();
    let interval = null;
    const delay = 15000;
    const maxTextLength = 160;
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    console.log('API_BASE_URL:', API_BASE_URL);

    useEffect(() => {
        setPost(posts[postIndex]);
    }, [postIndex]);

    useEffect(() => {
        if (posts.length > 0) {
            // Cycle posts
            setPostIndex(0);
            interval = setInterval(() => {
                setPostIndex((index) => index >= posts.length - 1 ? 0 : index + 1);
            }, delay);
        }
        return () => clearInterval(interval);
    }, [posts]);

    useEffect(() => {
        const loadPosts = async () => {
            var config = {
                method: 'get',
                url: `${API_BASE_URL}/api/instagram`,
                headers: {
                }
            };
            const response = await axios(config);
            // const mapped = response.data.map(p => {
            //     const node = p.node;
            //     return {
            //         id: node.id,
            //         imageUrl: node.display_url,
            //         squareImageUrl: node.thumbnail_resources.find(t => t.config_width === 640)?.src,
            //         text: node.edge_media_to_caption?.edges[0]?.node?.text
            //     }
            // });
            const mapped = response.data.map(entry => {
                return entry.posts.length > 0 ? entry.posts[0] : null;
            }).filter(entry => entry); // non null only
            console.log(mapped);
            setPosts(mapped);
            console.log(`Loaded ${mapped.length} posts`);
        }
        loadPosts();
    }, []);

    let imageUrl = post?.squareImageUrl ? `${API_BASE_URL}/api/instagram/image?url=${encodeURIComponent(post?.squareImageUrl)}` : null;
    if (imageUrl?.startsWith('http://localhost')) {
        const samples = [
            'https://images.pexels.com/photos/16389397/pexels-photo-16389397/free-photo-of-person-in-a-yellow-coat-standing-on-a-rock-in-a-mountain-valley.jpeg',
            'https://images.pexels.com/photos/15193665/pexels-photo-15193665/free-photo-of-cubes-and-wooden-boxes.jpeg',
            'https://images.pexels.com/photos/16747497/pexels-photo-16747497/free-photo-of-blue-door-of-a-mexican-townhouse.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
        ]
        imageUrl = samples[Math.floor(Math.random() * samples.length)]; // Don't load locally
    }

    let text = post?.text;
    if (text) {
        text = text.length > maxTextLength ? text.substring(0, maxTextLength).trim() + '...' : text;
    }

    const username = post?.account?.username ?? '';

    return (
        <Box sx={{
            height: '100%',
            padding: '3rem',
            width: '100%'
        }}>
            <Stack
                sx={{
                    height: '100%',
                    width: '100%'
                }}
                spacing={1}
                direction="column">
                {/* <Typography
                    sx={{
                        minHeight: '44.18px' // To match the MTS logo height
                    }}
                    variant="h3"
                    component="h3">North Park Happenings</Typography> */}
                <Stack
                    sx={{
                        width: '100%',
                        height: '100%'
                    }}
                    spacing={1}
                    direction="column">
                    <Box sx={{
                        aspectRatio: '1',
                        backgroundColor: '#333',
                        width: '100%',
                        height: 'auto',
                    }}>
                        {/* {imageUrl && */}
                            <Image
                                key={imageUrl}
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    aspectRatio: '1'
                                }}
                                bgColor="#252722"
                                src={imageUrl}
                                easing="ease-in-out"
                                duration={1500}
                                fit="cover"
                                crossOrigin='anonymous' />
                        {/* } */}
                    </Box>
                    <Stack
                        sx={{
                            padding: '0.5rem',
                        }}
                        direction="column">
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                letterSpacing: '0.1rem',
                                marginBottom: '1rem',
                                marginTop: '0.5rem',
                            }}
                        >NORTH PARK HAPPENINGS</Typography>
                        <Stack
                            direction="row">
                            <img src={InstaLogo} style={{
                                height: 'auto',
                                marginRight: '1rem',
                                width: '48px',
                            }} />
                            <Typography
                                variant="h3"
                                color="text.secondary"
                                sx={{
                                    fontSize: '2.0rem'
                                }}
                            >@{username}</Typography>
                        </Stack>
                        <Box sx={{
                            overflow: 'hidden',
                            // whiteSpace: 'nowrap',
                            height: '120px',
                            marginTop: '0.5rem',
                            textOverflow: 'ellipsis'
                        }}>
                            <Typography
                                variant="mono"
                                sx={{
                                    fontSize: '16px',
                                }}>
                                {text}
                                {/* 🏄‍♂️SURFS UP🌊 Join us in PACIFIC BEACH on Monday, March 20th at 6pm for a very special edition of our Monday Run Club. <br/><br/>On Running is releasing an all-new Cloudsurfer! Not only will they be bringing shoes to demo, but they will be bringing  @leilanisfoodtruck along with them, with FREE food for all of our runners! <br/><br/>We’re going for a very Surfer/Beach-like vibe, so come dress in a fun outfit! Think floral shirts, bright colors, and your best pair of shades 😎<br/><br/>See you all Monday! 6pm, don’t be late!<br/><br/>1892 Garnet Ave, San Diego, CA 92109 */}
                            </Typography>
                        </Box>
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    );
}

export default SocialMediaPanel;