import logo from './logo.svg';
import './App.css';
import Layout from './components/Layout';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";
import moment from 'moment-timezone';

import theme from './theme';
import AutoRefresh from './components/AutoRefresh';

function App() {

  // Configure default timezone to PST
  moment.tz.setDefault('America/Los_Angeles');

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AutoRefresh />
      <Layout></Layout>
    </ThemeProvider>
  );
}

export default App;
